import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { trimEnd } from "lodash"

/**
 * Renders SEO meta tags such as the meta description tag and "og:*" tags. The `seo` prop contains
 * the optional SEO object from the CMS. The values in this object take precedence. The other
 * props can be used to provide default values.
 */
const SEO = ({ description, imageUrl, pathname, seo, type = "website" }) => {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  seo ||= {}

  if (seo.description) {
    description = seo.description
  }

  if (seo.imageUrl) {
    imageUrl = seo.imageUrl
  }

  if (
    imageUrl &&
    imageUrl.startsWith("https://cdn.sanity.io/") &&
    !imageUrl.includes("?")
  ) {
    imageUrl += "?fit=fillmax&w=1200&h=627"
  }

  const bodyClassName = /apparel|product/.test(pathname)
    ? "bg-clayLight text-onyx"
    : "bg-onyx text-white"

  return (
    <Helmet>
      {/* og:title tag is rendered in layout along with <title> */}
      <script>
        {`
          window.onerror = (error) => {
            // DIRTY HACK FOR MINDBODY
            if (error.indexOf("read properties of null (reading 'match')")) window.location.reload()
          }
        `}
      </script>

      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}

      {imageUrl && <meta property="og:image" content={imageUrl} />}

      <meta property="og:type" content={type} />
      <meta
        property="og:url"
        content={`${siteUrl}${trimEnd(pathname, "/") || "/"}`}
      />
      <script
        src="https://widgets.mindbodyonline.com/javascripts/healcode.js"
        type="text/javascript"
      />
      <body className={bodyClassName} />
    </Helmet>
  )
}

SEO.propTypes = {
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  seo: PropTypes.object,
  type: PropTypes.string,
}

export default SEO
