import { capitalize } from "lodash"
import React, { useEffect } from "react"
import CollectionPage from "../components/collections/CollectionPage"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { gatsbyTemplateTypes } from "../lib/propTypes"
import { trackCollectionView, trackPageType } from "../lib/tracking/tracking"

const CollectionTemplate = ({
  location,
  pageContext: { collection, filter, parent },
}) => {
  useEffect(() => {
    trackPageType("collections")
    trackCollectionView(collection.shopifyCollection)
  }, [collection.path.current])

  const filterValues = Object.values(filter || {})
    .filter(i => i)
    .map(capitalize)

  return (
    <Layout
      previewNote="Previewing unpublished changes is not enabled for collection pages."
      title={[collection.title, ...filterValues].join(" | ")}
    >
      <SEO seo={collection.seo} pathname={location.pathname} />
      <CollectionPage
        key={collection._id}
        collection={collection}
        filter={filter}
        parent={parent || collection}
      />
    </Layout>
  )
}

CollectionTemplate.propTypes = gatsbyTemplateTypes

export default CollectionTemplate
